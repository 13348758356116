import React from "react"
import { Link } from "gatsby"

import BigWave from "../components/staticAnimations/wavesBackground/BigWave"

export default function NotFoundPage() {
  return (
    <>
      <div className="not-found">
        <h1>Sorry, this page doesn't exist.</h1>
        <p>
          Are you lost? Head back to the <Link to="/">home</Link> page
        </p>
        <BigWave />
      </div>
    </>
  )
}

export function Head() {
  return (
    <>
      <title>404| Lower Park Marina</title>
      <meta name="description" content="Looks like this page doesn't exist!" />
    </>
  )
}
